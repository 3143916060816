import { ofType } from 'redux-observable';
import { mergeMap, catchError } from 'rxjs/operators';
import { of, concat } from 'rxjs';
import { httpMethodGetJSON, httpMethodPatch, httpMethodDelete, httpMethodPost, } from '../https';
import { URL } from '../constants';
import { GET_ASSET_CATEGORY_FOR_SME, GET_ASSETS_FOR_ASSET_CATEGORY, GET_NETWORKS_FOR_NETWORK_CATEGORY, GET_TEMPLATE_DETAILS_FOR_NETWORK } from './constant';

export const getAssetCategoryForSme = action$ => action$.pipe(
  ofType(GET_ASSET_CATEGORY_FOR_SME),
  mergeMap(action => {
    return httpMethodGetJSON(action, `${URL}/asset-cbm/app-list/`);
  })
);


export const getAssetsForAssetCategory = action$ => action$.pipe(
  ofType(GET_ASSETS_FOR_ASSET_CATEGORY),
  mergeMap(action => {
    return httpMethodGetJSON(action, `${URL}/asset-cbm/asset-list/sme/${action.payload.asset_category_id}/`);
  })
);

export const getNetworksForNetworkCategory = action$ => action$.pipe(
  ofType(GET_NETWORKS_FOR_NETWORK_CATEGORY),
  mergeMap(action => {
    return httpMethodGetJSON(action, `${URL}/network/sme/network/list/${action.payload.network_type_id}/`);
  })
);

export const getTemplateDetailsForNetwork = action$ => action$.pipe(
  ofType(GET_TEMPLATE_DETAILS_FOR_NETWORK),
  mergeMap(action => {
    return httpMethodGetJSON(action, `${URL}/gstemp/service/${action.payload.service_id}/templates/`);
  })
);


export const appExpertEpics = {
    getAssetCategoryForSme,
    getAssetsForAssetCategory,
    getNetworksForNetworkCategory,
    getTemplateDetailsForNetwork
}