import * as AppConstants from './constant';

export function getCalculatedParameterList(value) {
    return {
        type: AppConstants.GET_ALL_CALCULATED_PARAMETER_LIST,
        payload: value
    }
}

export function isOpenCalculatedParameterModal(value) {
    return {
        type: AppConstants.IS_OPEN_CALCULATED_PARAMETER_MODAL,
        payload: value
    }
}

export function checkAllCalculatedParameters(checkedFlag, value) {
    return {
        type: AppConstants.CHECK_ALL_CALCULATED_PARAMETERS,
        payload: { checkedFlag, value }
    }
}

export function checkCalculatedParameter(isChecked, data) {
    return {
        type: AppConstants.CHECK_CALCULATED_PARAMETER,
        payload: { isChecked, data }
    }
}

export function checkAllAssetCalculatedParameters(isChecked, data) {
  return {
    type: AppConstants.CHECK_ALL_ASSET_CALCULATED_PARAMETERS,
    payload: { isChecked, data }
  };
}

export function getAllClientList(value) {
    return {
        type: AppConstants.GET_ALL_CLIENT_LIST,
        payload: value
    }
}

export function getAllPlantList(value) {
    return {
        type: AppConstants.GET_ALL_PLANT_LIST,
        payload: value
    }
}

export function deleteCalculatedParameter(value) {
    return {
        type: AppConstants.DELETE_CALCULATED_PARAMETER,
        payload: value
    }
}

export function resetDeletedCalParameter(value) {
    return {
        type: AppConstants.DELETE_CALCULATED_PARAMETER_RESET,
        payload: value
    }
}

export function resetDeletedNetwork(value) {
    return {
        type: AppConstants.DELETE_NETWORK_RESET,
        payload: value
    }
}


export function createCalculatedParameter(value) {
    return {
        type: AppConstants.CREATE_CALCULATED_PARAMETERS,
        payload: value
    }
}

export function resetCreateCalculatedParameter(value) {
    return {
        type: AppConstants.CREATE_CALCULATED_PARAMETER_RESET,
        payload: value
    }
}

export function resetCheckdataCalculatedParameter(value) {
    return {
        type: AppConstants.CHECKDATA_CALCULATED_PARAMETER_RESET,
        payload: value
    }
}

export function updateCalculatedParameter(value) {
    return {
        type: AppConstants.UPDATE_CALCULATED_PARAMETER,
        payload: value
    }
}

export function resetUpdateCalculatedParameter(value) {
    return {
        type: AppConstants.UPDATE_CALCULATED_PARAMETER_RESET,
        payload: value
    }
}

export function verifyTargetSensorParameter(value) {
    return {
        type: AppConstants.VERIFY_TARGET_SENSOR_PARAMETER,
        payload: value
    }
}

export function getTemplateList(value) {
    return {
        type: AppConstants.GET_ALL_TEMPLATE_LIST,
        payload: value
    }
}

export function getAssetNameDetails(value) {
    return {
        type: AppConstants.GET_ASSET_NAME_DETAILS,
        payload: value
    }
}

export const getAssetNameDetailsReset = (value) => {
  return {
    type: AppConstants.GET_ASSET_NAME_DETAILS_RESET,
    payload: value
  };
};

export function checkAllTemplates(checkedFlag, value) {
    return {
        type: AppConstants.CHECK_ALL_TEMPLATES,
        payload: { checkedFlag, value }
    }
}

export function checkAllServices(checkedFlag, value) {
    return {
        type: AppConstants.CHECK_ALL_SERVICES,
        payload: { checkedFlag, value }
    }
}

export function checkTemplate(isChecked, data) {
    return {
        type: AppConstants.CHECK_TEMPLATE,
        payload: { isChecked, data }
    }
}

export function checkService(isChecked, data) {
    return {
        type: AppConstants.CHECK_SERVICE,
        payload: { isChecked, data }
    }
}

export function getTemplateDetails(value) {
    return {
        type: AppConstants.GET_TEMPLATE_DETAILS,
        payload: value
    }
}

export function updateTemplate(value) {
    return {
        type: AppConstants.UPDATE_TEMPLATE,
        payload: value
    }
}

export function resetUpdateTemplate(value) {
    return {
        type: AppConstants.UPDATE_TEMPLATE_RESET,
        payload: value
    }
}

export function resetUpdateService(value) {
    return {
        type: AppConstants.UPDATE_SERVICE_TEMPLATE_RESET,
        payload: value
    }
}

export function deleteTemplate(value) {
    return {
        type: AppConstants.DELETE_TEMPLATE,
        payload: value
    }
}

export function resetDeletedTemplate(value) {
    return {
        type: AppConstants.DELETE_TEMPLATE_RESET,
        payload: value
    }
}

export function resetDeletedService(value) {
    return {
        type: AppConstants.DELETE_SERVICE_TEMPLATE_RESET,
        payload: value
    }
}

export function getAllAssetList(value) {
    return {
        type: AppConstants.GET_ALL_ASSET_LIST,
        payload: value
    }
}

export function createTemplate(value) {
    return {
        type: AppConstants.CREATE_TEMPLATES,
        payload: value
    }
}

export function resetCreateTemplate(value) {
    return {
        type: AppConstants.CREATE_TEMPLATE_RESET,
        payload: value
    }
}

export function resetCreateService(value) {
    return {
        type: AppConstants.CREATE_SERVICE_TEMPLATE_RESET,
        payload: value
    }
}

// export function getMetaDataForm(value) {
//     return {
//         type: AppConstants.GET_ALL_METADATA,
//         payload: value
//     }
// }

// export function getListOfAvailableDashboard(value) {
//     return{
//         type: AppConstants.GET_ALL_DASHBOARD_LIST,
//         response: value
//     }
// }

export function isOpenScreenDesignModal(value) {
  return {
    type: AppConstants.IS_OPEN_SCREEN_DESIGN_MODAL,
    payload: value
  };
}

export function addTemplateWidget(value) {
    return {
        type: AppConstants.ADD_TEMPLATE_WIDGET,
        payload: value
    }
}

export function resetAddTemplateWidget(value) {
    return {
        type: AppConstants.ADD_TEMPLATE_WIDGET_RESET,
        payload: value
    }
}

export function getTemplateWidgets(value) {
    return {
        type: AppConstants.GET_TEMPLATE_WIDGETS,
        payload: value
    }
}

export function getWidgetDetailsOfTemplate(value) {
    return {
        type: AppConstants.GET_WIDGET_DETAILS_TEMPLATE,
        payload: value
    }
}

export function updateWidgetTemplate(value) {
    return {
        type: AppConstants.UPDATE_WIDGET_TEMPLATE,
        payload: value
    }
}


export function resetUpdateWidgetTemplate(value) {
    return {
        type: AppConstants.UPDATE_WIDGET_TEMPLATE_RESET,
        payload: value
    }
}
export function resetDeleteDashboardWidget(value) {
    return {
        type: AppConstants.DELETE_DASHBOARD_WIDGET_RESET,
        payload: value
    }
}
export function deleteWidgetOfTemplate(value) {
    return {
        type: AppConstants.DELETE_WIDGET_OF_TEMPLATE,
        payload: value
    }
}

export function resetDeleteWidgetOfTemplate(value) {
    return {
        type: AppConstants.DELETE_WIDGET_OF_TEMPLATE_RESET,
        payload: value
    }
}

export function getDefaultTemplateAsset(value) {
    return {
        type: AppConstants.GET_DEFAULT_TEMPLATE_OF_ASSET,
        payload: value
    }
}

export function resetAddWidgetTemplate(value) {
    return {
        type: AppConstants.ADD_TEMPLATE_WIDGET_RESET,
        payload: value
    }
}

export function getServiceTemplatePlant(value) {
    return {
        type: AppConstants.GET_SERVICE_TEMPLATE_PLANT,
        payload: value
    }
}

export function getClientTemplate(value) {
    return {
        type: AppConstants.GET_CLIENT_TEMPLATE,
        payload: value
    }
}

export function createServiceTemplate(value) {
    return {
        type: AppConstants.CREATE_SERVICE_TEMPLATE,
        payload: value
    }
}

export function getServiceTemplate(value) {
    return {
        type: AppConstants.GET_SERVICE_TEMPLATE,
        payload: value
    }
}

export function updateServiceTemplate(value) {
    return {
        type: AppConstants.UPDATE_SERVICE_TEMPLATE,
        payload: value
    }
}

export function deleteServiceTemplate(value) {
    return {
        type: AppConstants.DELETE_SERVICE_TEMPLATE,
        payload: value
    }
}

export function getWidgetsDetails(value) {
    return {
        type: AppConstants.GET_WIDGETS_DETAILS,
        payload: value
    }
}

export function createDashboardTemplate(value) {
    return {
        type: AppConstants.CREATE_DASHBOARD_TEMPLATE,
        payload: value
    }
}

export const createDashboardTemplateReset = (value) => {
  return {
    type: AppConstants.CREATE_DASHBOARD_TEMPLATE_RESET,
    payload: value
  };
};

export function getTemplateDashboard(value) {
    return {
        type: AppConstants.GET_DASHBOARD_TEMPLATE,
        payload: value
    }
}

export function addDashboardWidget(value) {
    return {
        type: AppConstants.ADD_DASHBOARD_WIDGET,
        payload: value
    }
}
export function resetAddDashboardWidget(value) {
    return {
        type: AppConstants.ADD_DASHBOARD_WIDGET_RESET,
        payload: value
    }
}

export function getDashboardWidgets(value) {
    return {
        type: AppConstants.GET_DASHBOARD_WIDGETS,
        payload: value
    }
}

export function deleteDashboardWidget(value) {
    return {
        type: AppConstants.DELETE_DASHBOARD_WIDGET,
        payload: value
    }
}

export function updateDashboardWidget(value) {
    return {
        type: AppConstants.EDIT_DASHBOARD_WIDGET,
        payload: value
    }
}

export function editDashboardOrder(value) {
    return {
        type: AppConstants.EDIT_DASHBOARD_ORDER,
        payload: value
    }
}

export function editWidgetService(value) {
    return {
        type: AppConstants.EDIT_WIDGET_SERVICE,
        payload: value
    }
}

export function makeServiceDefault(value) {
    return {
        type: AppConstants.SERVICE_DEFAULT,
        payload: value
    }
}

export const makeServiceDefaultReset = (value) => {
  return {
    type: AppConstants.SERVICE_DEFAULT_RESET,
    payload: value
  };
};

export const makeServiceDefaultExtended = (value) => {
  return {
    type: AppConstants.SERVICE_DEFAULT_EXTENDED,
    payload: value
  };
};

export const makeServiceDefaultExtendedReset = (value) => {
  return {
    type: AppConstants.SERVICE_DEFAULT_EXTENDED_RESET,
    payload: value
  };
};

export const getAssetSensorTypes = (value) => {
    return {
        type: AppConstants.GET_ASSET_SENSOR_TYPES,
        payload: value
    }
}

export const getAllAssetCalculatedList = (value) => {
    return {
        type: AppConstants.GET_ALL_ASSET_CALCULATED_LIST,
        payload: value
    }
}

export function checkedAssetCalculatedParameter(isChecked, data) {
    return {
        type: AppConstants.CHECK_ASSET_CALCULATED_PARAMETER,
        payload: { isChecked, data }
    }
}

export function createAssetCalculatedParameter(value) {
    return {
        type: AppConstants.CREATE_ASSET_CALCULATED_PARAMETERS,
        payload: value
    }
}


export function updateAssetCalculatedParameter(value) {
    return {
        type: AppConstants.UPDATE_ASSET_CALCULATED_PARAMETER,
        payload: value
    }
}


export function resetAssetCreateCalculatedParameter(value) {
    return {
        type: AppConstants.CREATE_ASSET_CALCULATED_PARAMETER_RESET,
        payload: value
    }
}

export function resetAssetUpdateCalculatedParameter(value) {
    return {
        type: AppConstants.UPDATE_ASSET_CALCULATED_PARAMETER_RESET,
        payload: value
    }
}

export function resetCheckdataAssetCalculatedParameter(value) {
    return {
        type: AppConstants.CHECKDATA_ASSET_CALCULATED_PARAMETER_RESET,
        payload: { value }
    }
}

export const getFacilityHoliday = (value) => {
  return {
    type: AppConstants.GET_FACILITY_HOLIDAY,
    payload: value
  };
};

export const deletePlantAllHoliday = (value) => {
  return {
    type: AppConstants.DELETE_PLANT_ALL_HOLIDAY,
    payload: value
  };
};

export const resetDeletePlantAllHoliday = (value) => {
  return {
    type: AppConstants.DELETE_PLANT_ALL_HOLIDAY_RESET,
    payload: value
  };
};

export const getNetworkType = (value) => {
  return {
    type: AppConstants.GET_NETWORK_TYPE,
    payload: value
  };
};

export const getDynamicVariableTemplate = (value) => {
  return {
    type: AppConstants.GET_DYNAMIC_VARIABLE_TEMPLATE,
    payload: value
  };
};

export const updateDynamicVariableTemplate = (value) => {
  return {
    type: AppConstants.UPDATE_DYNAMIC_VARIABLE_TEMPLATE,
    payload: value
  };
};

export const resetUpdateDynamicVariableTemplate = (value) => {
  return {
    type: AppConstants.UPDATE_DYNAMIC_VARIABLE_TEMPLATE_RESET,
    payload: value
  };
};

export const deleteDynamicVariableTemplate = (value) => {
  return {
    type: AppConstants.DELETE_DYNAMIC_VARIABLE_TEMPLATE,
    payload: value
  };
};

export const resetDeleteDynamicVariableTemplate = (value) => {
  return {
    type: AppConstants.DELETE_DYNAMIC_VARIABLE_TEMPLATE_RESET,
    payload: value
  };
};

export const getDynamicVariableMapping = (value) => {
  return {
    type: AppConstants.GET_DYNAMIC_VARIABLE_MAPPING,
    payload: value
  };
};

export const updateDynamicVariableMapping = (value) => {
  return {
    type: AppConstants.UPDATE_DYNAMIC_VARIABLE_MAPPING,
    payload: value
  };
};

export const resetUpdateDynamicVariableMapping = (value) => {
  return {
    type: AppConstants.UPDATE_DYNAMIC_VARIABLE_MAPPING_RESET,
    payload: value
  };
};

export const deleteDynamicVariableMapping = (value) => {
  return {
    type: AppConstants.DELETE_DYNAMIC_VARIABLE_MAPPING,
    payload: value
  };
};

export const resetDeleteDynamicVariableMapping = (value) => {
  return {
    type: AppConstants.DELETE_DYNAMIC_VARIABLE_MAPPING_RESET,
    payload: value
  };
};

export function _getAssetDynamicVariables(value) {
  return {
    type: AppConstants.GET_ASSET_DYNAMIC_VARIABLES,
    payload: value
  };
}

export function _getNetworkDynamicVariables(value) {
  return {
    type: AppConstants.GET_NETWORK_DYNAMIC_VARIABLES,
    payload: value
  };
}

export function getAllAssetAlerts(value) {
  return {
    type: AppConstants.GET_ALL_ASSET_ALERT_LIST,
    payload: value
  };
}


export function getAllNetworkAlerts(value) {
  return {
    type: AppConstants.GET_ALL_NETWORK_ALERT_LIST,
    payload: value
  };
}
export function createAssetAlert(value) {
  return {
    type: AppConstants.CREATE_ASSET_ALERT,
    payload: value
  };
}

export function resetCreateAssetAlert(value) {
  return {
    type: AppConstants.CREATE_ASSET_ALERT_RESET,
    payload: value
  };
}

export function updateAssetAlert(value) {
  return {
    type: AppConstants.UPDATE_ASSET_ALERT,
    payload: value
  };
}

export function resetUpdateAssetAlert(value) {
  return {
    type: AppConstants.UPDATE_ASSET_ALERT_RESET,
    payload: value
  };
}

export function deleteAssetAlertTemplate(value) {
  return {
    type: AppConstants.DELETE_ASSET_ALERT_TEMPLATE,
    payload: value
  };
}

export function deleteNetworkAlertTemplate(value) {
  return {
    type: AppConstants.DELETE_NETWORK_ALERT_TEMPLATE,
    payload: value
  };
}

export function resetDeletedAssetAlertTemplate(value) {
  return {
    type: AppConstants.DELETE_ASSET_ALERT_TEMPLATE_RESET,
    payload: value
  };
}

export function resetDeletedNetworkAlertTemplate(value) {
  return {
    type: AppConstants.DELETE_NETWORK_ALERT_TEMPLATE_RESET,
    payload: value
  };
}

export function createNetworkAlert(value) {
  return {
    type: AppConstants.CREATE_NETWORK_ALERT,
    payload: value
  }
}

export function resetCreateNetworkAlert(value) {
  return {
    type: AppConstants.CREATE_NETWORK_ALERT_RESET,
    payload: value
  };
}

export function updateNetworkAlert(value) {
  return {
    type: AppConstants.UPDATE_NETWORK_ALERT,
    payload: value
  };
}

export function resetUpdateNetworkAlert(value) {
  return {
    type: AppConstants.UPDATE_NETWORK_ALERT_RESET,
    payload: value
  };
}
 
export const getAllNetworkList = (value) => {
  return {
    type: AppConstants.GET_ALL_NETWORK_LIST,
    payload: value
  };
};

export const getAllNetworkListReset = (value) => {
  return {
    type: AppConstants.GET_ALL_NETWORK_LIST_RESET,
    payload: value
  };
};

export function createNetworkTemplate(value) {
  return {
    type: AppConstants.CREATE_NETWORK_TEMPLATES,
    payload: value
  };
}

export function resetCreateNetworkTemplate(value) {
  return {
    type: AppConstants.CREATE_NETWORK_TEMPLATES_RESET,
    payload: value
  };
}

export function updateNetwork(value) {
  return {
    type: AppConstants.UPDATE_NETWORK,
    payload: value
  };
}

export const resetUpdateNetwork = (value) => {
  return {
    type: AppConstants.UPDATE_NETWORK_RESET,
    payload: value
  };
};

export function deleteNetwork(value) {
  return {
    type: AppConstants.DELETE_NETWORK,
    payload: value
  };
}

export const resetDeleteNetwork = (value) => {
  return {
    type: AppConstants.DELETE_NETWORK_RESET,
    payload: value
  };
};

export const getNetworkCalculatedParamTemplate = (value) => {
  return {
    type: AppConstants.GET_NETWORK_CALCULATED_PARAM_TEMPLATE,
    payload: value
  };
};

export const deleteNetworkCalculatedParamTemplate = (value) => ({
  type: AppConstants.DELETE_NETWORK_CALCULATED_PARAM_TEMPLATE,
  payload: value
});

export const resetDeleteNetworkCalculatedParamTemplate = (value) => ({
  type: AppConstants.DELETE_NETWORK_CALCULATED_PARAM_TEMPLATE_RESET,
  payload: value
});

export const getWorkingNonWorkingHoursList = (value) => {
  return {
    type: AppConstants.GET_WORKING_NON_WORKING_HOURS_LIST,
    payload: value
  };
};

export const deleteWorkingNonWorkingHoursList = (value) => {
  return {
    type: AppConstants.DELETE_WORKING_NON_WORKING_HOURS_LIST,
    payload: value
  };
};

export const getProductionNonProductionHoursList = (value) => {
  return {
    type: AppConstants.GET_PRODUCTION_NON_PRODUCTION_HOURS_LIST,
    payload: value
  };
};

export const deleteProductionNonProductionHoursList = (value) => {
  return {
    type: AppConstants.DELETE_PRODUCTION_NON_PRODUCTION_HOURS_LIST,
    payload: value
  };
};

export const getShiftList = (value) => {
  return {
    type: AppConstants.GET_SHIFT_LIST,
    payload: value
  };
};

export const deleteShiftList = (value) => {
  return {
    type: AppConstants.DELETE_SHIFT_LIST,
    payload: value
  };
};
export function getNetworkInsightList(value) {
  return {
    type: AppConstants.GET_NETWORK_INSIGHT_LIST,
    payload: value
  };
}

export function getDetailsByNetworkInsightId(value) {
  return {
    type: AppConstants.GET_NETWORK_INSIGHT_DETAILS,
    payload: value
  };
}

export const getNetworkSensorTypes = (value) => {
  return {
    type: AppConstants.GET_NETWORK_SENSOR_TYPES,
    payload: value
  };
};

export const getNetworkReadingTypes = (value) => {
  return {
    type: AppConstants.GET_NETWORK_READING_TYPES,
    payload: value
  };
};

export function getAllActionsOfNetwork(value) {
  return {
    type: AppConstants.GET_ALL_ACTIONS_FOR_NETWORK,
    payload: value
  };
}

export function createManualNetworkInsight(value) {
  return {
    type: AppConstants.CREATE_MANUAL_NETWORK_INSIGHT,
    payload: value
  };
}

export const getNetworkSmeClientSensorLocations = (value) => {
  return {
    type: AppConstants.GET_NETWORK_SME_CLIENT_SENSOR_LOCATIONS,
    payload: value
  };
};

export const getNetworkSmeClientSensorLocationsReset = (value) => {
  return {
    type: AppConstants.GET_NETWORK_SME_CLIENT_SENSOR_LOCATIONS_RESET,
    payload: value
  };
};

export const createSystemTemplate = (value) => {
  return {
    type: AppConstants.CREATE_SYSTEM_TEMPLATE,
    payload: value
  };
};

export const createSystemTemplateReset = (value) => {
  return {
    type: AppConstants.CREATE_SYSTEM_TEMPLATE_RESET,
    payload: value
  };
};

export const updateSystemTemplate = (value) => {
  return {
    type: AppConstants.UPDATE_SYSTEM_TEMPLATE,
    payload: value
  };
};

export const updateSystemTemplateReset = (value) => {
  return {
    type: AppConstants.UPDATE_SYSTEM_TEMPLATE_RESET,
    payload: value
  };
};

export const deleteSystemTemplate = (value) => {
  return {
    type: AppConstants.DELETE_SYSTEM_TEMPLATE,
    payload: value
  };
};

export const deleteSystemTemplateReset = (value) => {
  return {
    type: AppConstants.DELETE_SYSTEM_TEMPLATE_RESET,
    payload: value
  };
};

export const getSystemTemplates = (value) => {
  return {
    type: AppConstants.GET_SYSTEM_TEMPLATES,
    payload: value
  };
};

export const getSystemTemplate = (value) => {
  return {
    type: AppConstants.GET_SYSTEM_TEMPLATE,
    payload: value
  };
};

export const getSystemTemplateReset = (value) => {
  return {
    type: AppConstants.GET_SYSTEM_TEMPLATE_RESET,
    payload: value
  };
};

export const getSystemTemplateWidgets = (value) => {
  return {
    type: AppConstants.GET_SYSTEM_TEMPLATE_WIDGETS,
    payload: value
  };
};

export const getSystemTemplateWidgetsReset = (value) => {
  return {
    type: AppConstants.GET_SYSTEM_TEMPLATE_WIDGETS_RESET,
    payload: value
  };
};

export const getSystemTemplateWidget = (value) => {
  return {
    type: AppConstants.GET_SYSTEM_TEMPLATE_WIDGET,
    payload: value
  };
};

export const getSystemTemplateWidgetReset = (value) => {
  return {
    type: AppConstants.GET_SYSTEM_TEMPLATE_WIDGET_RESET,
    payload: value
  };
};

export const _getSystemTemplateWidget = (value) => {
  return {
    type: AppConstants._GET_SYSTEM_TEMPLATE_WIDGET,
    payload: value
  };
};

export const _getSystemTemplateWidgetReset = (value) => {
  return {
    type: AppConstants._GET_SYSTEM_TEMPLATE_WIDGET_RESET,
    payload: value
  };
};

export const createSystemTemplateWidget = (value) => {
  return {
    type: AppConstants.CREATE_SYSTEM_TEMPLATE_WIDGET,
    payload: value
  };
};

export const createSystemTemplateWidgetReset = (value) => {
  return {
    type: AppConstants.CREATE_SYSTEM_TEMPLATE_WIDGET_RESET,
    payload: value
  };
};

export const updateSystemTemplateWidget = (value) => {
  return {
    type: AppConstants.UPDATE_SYSTEM_TEMPLATE_WIDGET,
    payload: value
  };
};

export const updateSystemTemplateWidgetReset = (value) => {
  return {
    type: AppConstants.UPDATE_SYSTEM_TEMPLATE_WIDGET_RESET,
    payload: value
  };
};

export const deleteSystemTemplateWidget = (value) => {
  return {
    type: AppConstants.DELETE_SYSTEM_TEMPLATE_WIDGET,
    payload: value
  };
};

export const deleteSystemTemplateWidgetReset = (value) => {
  return {
    type: AppConstants.DELETE_SYSTEM_TEMPLATE_WIDGET_RESET,
    payload: value
  };
};

export const updateSystemTemplateWidgetsOrdering = (value) => {
  return {
    type: AppConstants.UPDATE_SYSTEM_TEMPLATE_WIDGETS_ORDERING,
    payload: value
  };
};

export const updateSystemTemplateWidgetsOrderingReset = (value) => {
  return {
    type: AppConstants.UPDATE_SYSTEM_TEMPLATE_WIDGETS_ORDERING_RESET,
    payload: value
  };
};

export const getSystemNames = (value) => {
  return {
    type: AppConstants.GET_SYSTEM_NAMES,
    payload: value
  };
};

export const getSystemNamesReset = (value) => {
  return {
    type: AppConstants.GET_SYSTEM_NAMES_RESET,
    payload: value
  };
};

export const getSystemDetails = (value) => {
  return {
    type: AppConstants.GET_SYSTEM_DETAILS,
    payload: value
  };
};

export const getSystemDetailsReset = (value) => {
  return {
    type: AppConstants.GET_SYSTEM_DETAILS_RESET,
    payload: value
  };
};

export const getSystemMetaFields = (value) => {
  return {
    type: AppConstants.GET_SYSTEM_META_FIELDS,
    payload: value
  };
};

export const getSystemMetaFieldsReset = (value) => {
  return {
    type: AppConstants.GET_SYSTEM_META_FIELDS_RESET,
    payload: value
  };
};

export const getSystemDynamicVariableTemplate = (value) => {
  return {
    type: AppConstants.GET_SYSTEM_DYNAMIC_VARIABLE_TEMPLATE,
    payload: value
  };
};

export const deleteSystemDynamicVariableTemplate = (value) => {
  return {
    type: AppConstants.DELETE_SYSTEM_DYNAMIC_VARIABLE_TEMPLATE,
    payload: value
  };
};

export const resetDeleteSystemDynamicVariableTemplate = (value) => {
  return {
    type: AppConstants.DELETE_SYSTEM_DYNAMIC_VARIABLE_TEMPLATE_RESET,
    payload: value
  };
};

export const updateSystemDynamicVariableTemplate = (value) => {
  return {
    type: AppConstants.UPDATE_SYSTEM_DYNAMIC_VARIABLE_TEMPLATE,
    payload: value
  };
};

export const resetUpdateSystemDynamicVariableTemplate = (value) => {
  return {
    type: AppConstants.UPDATE_SYSTEM_DYNAMIC_VARIABLE_TEMPLATE_RESET,
    payload: value
  };
};

export const getSystemDynamicVariableMapping = (value) => {
  return {
    type: AppConstants.GET_SYSTEM_DYNAMIC_VARIABLE_MAPPING,
    payload: value
  };
};

export const updateSystemDynamicVariableMapping = (value) => {
  return {
    type: AppConstants.UPDATE_SYSTEM_DYNAMIC_VARIABLE_MAPPING,
    payload: value
  };
};

export const resetUpdateSystemDynamicVariableMapping = (value) => {
  return {
    type: AppConstants.UPDATE_SYSTEM_DYNAMIC_VARIABLE_MAPPING_RESET,
    payload: value
  };
};

export const deleteSystemDynamicVariableMapping = (value) => {
  return {
    type: AppConstants.DELETE_SYSTEM_DYNAMIC_VARIABLE_MAPPING,
    payload: value
  };
};

export const resetDeleteSystemDynamicVariableMapping = (value) => {
  return {
    type: AppConstants.DELETE_SYSTEM_DYNAMIC_VARIABLE_MAPPING_RESET,
    payload: value
  };
};