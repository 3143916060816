import {
  CREATE_SYSTEM_DYNAMIC_VARIABLE_MAPPING,
  CREATE_SYSTEM_DYNAMIC_VARIABLE_MAPPING_SUCCESS,
  CREATE_SYSTEM_DYNAMIC_VARIABLE_MAPPING_FAILURE,
  CREATE_SYSTEM_DYNAMIC_VARIABLE_MAPPING_RESET
} from './constant';

export const createSystemDynamicVariableMapping = (
  state = {
    data: null,
    loading: false,
    success: undefined,
    error: false
  },
  action
) => {
  switch (action.type) {
    case CREATE_SYSTEM_DYNAMIC_VARIABLE_MAPPING:
      return {
        ...state,
        loading: true
      };

    case CREATE_SYSTEM_DYNAMIC_VARIABLE_MAPPING_SUCCESS:
      return {
        ...state,
        data: action.response,
        loading: false,
        success: true
      };

    case CREATE_SYSTEM_DYNAMIC_VARIABLE_MAPPING_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload.response
      };

    case CREATE_SYSTEM_DYNAMIC_VARIABLE_MAPPING_RESET:
      return {
        ...state,
        success: undefined,
        error: false
      };

    default:
      return state;
  }
};
